._2edfe2d43e {
  height: 100%;
  width: 100%;
}

.c7cae0fecb {
  margin: auto auto;
  max-width: 500px;
  min-height: 100vh;
  padding: 10% 2%;
  position: relative;
  text-align: center;
}

.fill {
  object-fit: cover;
}
.point {
  cursor: pointer;
}
img {
  object-fit: cover;
}
img {
  border-style: none;
}
._08862925bf {
  font-size: 15px;
  font-weight: 700;
  line-height: 40px;
}
.db3d8b972c {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}
._09eeed7ff6 {
  line-height: 25px;
}

.db3d8b972c {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}
._09eeed7ff6 {
  line-height: 25px;
}
.db3d8b972c {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
}
._90f8a62e19 {
  background-color: #5b86b3;
  color: #fff;
  cursor: pointer;
  flex: 1 1;
  line-height: 40px;
  margin: 20px 0;
  padding: 0 10px;
}

._519ff961fe {
  text-decoration: none;
}
