* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

.hidden {
  display: none;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

a {
  text-decoration: none;
  color: #ffffffcc;
}

body {
  font-size: 0.24rem;
  max-width: 600px;
  width: 100%;
  margin: 0 auto;
  color: #ffffff;
  overflow-y: auto;
  font-family: -apple-system, BlinkMacSystemFont, Microsoft YaHei, Arial, sans-serif;
  /* background-repeat: no-repeat; */
  /* background-size: 100%; */
  background-position: top left;
  background-image: url(/public/bg.png);
  background-color: #101010;
  min-height: 100vh; /* 确保内容少时也占满页面 */
  background-size: cover; /* 图片自适应页面大小 */

  background-attachment: fixed; /* 背景固定，随滚动条滚动 */
  background-repeat: no-repeat; /* 不重复 */
}

.app {
  font-size: 0.24rem;
}

.text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.my-sticky {
  position: sticky;
  top: 0;
  display: flex;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position: top center;
  height: 1.2rem;
  align-items: center;
  background-image: url(/public/bg.png);
  z-index: 11;
  padding: 8px;
}

.navtop {
  display: flex;
  flex: 1;
}

.navtop .navtop-item {
  flex: 1;
  align-items: center;
  display: flex;
  cursor: pointer;
}

/* .navtop img {
    width: 1.5rem;
    height: 0.8rem;
  } */

.navtop-item-title {
  position: relative;
  text-align: center;
  font-size: 0.32rem;
  font-family: "Arial", sans-serif;
  background: linear-gradient(90deg, #b380ff, #a76ef5);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; /* 仅填充文字颜色为渐变 */
  display: inline-block;
  text-shadow: 0px 1px 2px rgba(229, 220, 220, 0.02); /* 添加轻微阴影让文字更亮 */
}

.navtop-item-title.active {
  font-size: 0.38rem;
  font-weight: 900; /* 特别粗 */
  background: linear-gradient(#a34bf0, #a76ef5, #a76ef5); /* 紫到粉渐变 */
  -webkit-background-clip: text; /* 裁剪背景到文字形状 */
  -webkit-text-fill-color: transparent; /* 填充文字颜色为透明，显示背景 */
  color: transparent;
  text-shadow: 0px 1px 2px rgba(229, 220, 220, 0.2); /* 添加轻微阴影让文字更亮 */
}

.navtop-item-title.active::after {
  content: "";
  width: 50%;
  height: 0.06rem;
  display: block; /* 设置为 block 避免干扰文字 */
  position: absolute;
  top: 0.6rem;
  align-items: center;
  left: 25%;
  transform: translateY(-80%);
  border-radius: 0.5rem;
  background: linear-gradient(#a34bf0, #a76ef5, #a76ef5);
  background: -webkit-linear-gradient(left, #a76ef5, #a76ef5);
  background: -o-linear-gradient(left, #a76ef5, #a76ef5);
  background-size: 100% 100%; /* 确保下划线的大小适配容器 */
  background-repeat: no-repeat;
}

.top-ad {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.22rem;
  margin-bottom: 0.24rem;
}
.top-ad-item-1 {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: top left;
  align-items: center;
  height: 1.2rem;
  display: flex;
  justify-content: flex-start; /* 子元素水平居中 */
  gap: 5px;
}

.top-ad-item:nth-child(odd) .top-ad-item-1 {
  background-image: url(/public/item-l.png);
}

.top-ad-item:nth-child(even) .top-ad-item-1 {
  background-image: url(/public/item-r.png);
}

.top-ad-img {
  left: -0.3rem;
  width: 0.96rem;
  height: 0.96rem;
  border-radius: 0.2rem;
}

.top-ad-title {
  color: #fee859;
  font-weight: 600;
  font-size: 0.3rem;
  margin-bottom: 0.04rem;
}

.top-ad-name {
  color: #ffffff80;
  font-weight: 600;
  font-size: 0.26rem;
  vertical-align: middle;
}

.top-ad-text {
  color: #ffffff80;
  font-size: 0.24rem;
}

.swiper-container {
  padding-top: 0.24rem !important;
  margin: 0 0.1rem !important;
}

/* 官方推荐app */
.gftj-wrap {
  margin-bottom: 0.16rem;
}

.gftj-title {
  font-size: 0.28rem;
  color: #ffffffcc;
  margin-bottom: 0.16rem;
}

.gftj-content {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  gap: 0.22rem;
}

.gftj-item {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.gftj-img {
  width: 1.2rem;
  height: 1.2rem;
  border-radius: 0.2rem;
  margin-bottom: 0.12rem;
}

/* 文本广告位 */

.ad-text-wrap {
  display: grid;
  gap: 5px;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  margin-bottom: 0.24rem;
}

.ad-text-item {
  background-color: #242424;
  height: 0.64rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.1rem;
  width: 100%;
  color: #ffffffcc;
}

.ad-text-icon {
  margin-right: 2px;
}

.ad-text-item.h {
  color: #ffb29e;
}

.ad-text-item.n {
  color: #5fbecd;
}

/** 子导航 */
.nav1,
.nav2 {
  display: flex;
  align-items: center;
  height: 0.54rem;
  margin-bottom: 0.16rem;
}
.nav-item {
  color: #ffffff99;
  margin-right: 0.4rem;
  font-size: 0.28rem;
  cursor: pointer;
}

.nav-item:last-child {
  margin-right: 0;
}

.nav-item.active {
  color: #ffffffcc;
  font-weight: 600;
  font-size: 0.32rem;
}

.nav-item.active::after {
  content: "";
  width: 50%;
  height: 0.06rem;
  position: relative;
  display: block;
  top: 0.1rem;
  left: 25%;
  transform: translateY(-25%);
  border-radius: 0.5rem;
  background-color: #fee85a;
  margin-bottom: 0.16rem;
}

.waterfall-flow {
  columns: 2;
  column-gap: 5px;
  margin-bottom: 20px;
}

.flow-item {
  display: block;
  width: 100%;
  border-radius: 10px;
  background-color: #1f2228;
  break-inside: avoid;
  margin-bottom: 5px;
}

.flow-text-wrap {
  padding: 8px;
}

.flow-img-wrap {
  position: relative;
}

.flow-ab {
  position: absolute;
  width: 100%;
  padding: 0 8px;
  bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffffcc;
}

.sound {
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 0.82rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  padding: 0 3px;
}

.praise {
  border-radius: 0.82rem;
  height: 0.4rem;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  padding: 0 3px;
}

.sound img,
.praise img {
  margin-right: 3px;
}

.flow-img {
  width: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  min-height: 3.26rem;
}

.flow-text1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flow-name {
  flex: 0 0 auto;
  width: 1.92rem;
  font-size: 0.24rem;
  font-weight: 700;
  color: var(--text-color10);
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.flow-add {
  font-size: 0.18rem;
}

.flow-tips {
  width: 100%;
  display: flex;
}

.flow-tips div {
  background-color: #00ff001a;
  color: #64b264;
  border-radius: 30px;
  height: 0.32rem;
  line-height: 0.32rem;
  padding: 0 4px;
  margin: 6px 0;
  font-size: 0.2rem;
  margin-right: 0.08rem;
}

.swiper2 {
  overflow: hidden;
}

.flow-text2 {
  color: var(--text-color12);
  line-height: 16px;
}
.g-name {
  font-size: 0.24rem;
}
.g-info {
  margin-left: 2px;
  color: #9582b4;
  font-weight: 600;
}
.text2 {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2; /* 表示する行数 */
  overflow: hidden;
  text-overflow: ellipsis;
}
.g-pic {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.12rem;
  margin-bottom: 0.06rem;
}
.g-bg {
  background-position: top left;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 1.62rem;
  height: 0.48rem;
  text-align: center;
  line-height: 0.48rem;
}
.g-l-bg {
  background-image: url(/public/l-bg.svg);
}
.g-r-bg {
  background-image: url(/public/r-bg.svg);
}

.live-wrap {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 0.22rem;
}
.live-item {
}
.center-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -60%);
}
.center-icon img {
  /* width: 2.4rem; */
  height: auto;
}
.live-wrap .live-item-img {
  width: 100%;
  height: 5rem;
  border-radius: 0.2rem;
}
.live-item-wrap {
  position: relative;
}
.bt-info {
  display: flex;
  justify-content: space-between;
}
.bt-info div {
  flex: 0 1 auto;
}
.live-left img {
  width: 2.52rem;
  height: auto;
}

.live-left {
  vertical-align: middle;
  align-items: center;
  display: flex;
  justify-content: center;
}

.live-watching {
  font-size: 0.2rem;
  margin-left: 0.08rem;
}

.live-count {
  color: #f1b096;
  font-weight: 600;
}

.live-tab {
  position: absolute;
  top: 0.07rem;
  left: 0.09rem;
  padding: 0.03rem 0.08rem;
  font-size: 0.15rem;
  color: #fff;
  line-height: 0.24rem;
  background: linear-gradient(#ff9537, #ff9537, #ffbc2b);
  background: -webkit-linear-gradient(left, #ff9537, #ffbc2b);
  background: -o-linear-gradient(left, #ff9537, #ffbc2b);
  border-radius: 0.1rem;
  opacity: 0.9;
}

.onLine {
  position: absolute;
  right: 0.07rem;
  top: 0.07rem;
  height: 0.25rem;
}

.wel-wrapper {
  height: 100%;
  width: 100%;
}

.wel-content {
  margin: 0 auto;
  max-width: 500px;
  min-height: 100vh;
  padding: 5% 2%;
  position: relative;
  text-align: center;
}
